import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import styled from "@emotion/styled";
import mediaqueries from "@styles/media";
import { useColorMode } from "theme-ui";

function slugify(string) {
  const slug = string
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036F]/g, "")
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)+/g, "");

  return `${slug}`;
}

function createDataObject(heading) {
  return {
    link: "#" + slugify(heading.value),
    name: heading.value,
    values: [],
  };
}

function ListItem({ item }) {
  let children = null;
  if (item.values && item.values.length) {
    children = (
      <ul>
        {item.values.map((i) => (
          <ListItem item={i} key={i.name} />
        ))}
      </ul>
    );
  }

  return (
    <li key={item.name}>
      <AnchorLink offset="100" href={item.link}>
        {item.name}
      </AnchorLink>
      {children}
    </li>
  );
}

const TOC = ({ headings }) => {
  const [colorMode] = useColorMode();
  const isDark = colorMode === "dark";

  var data = [];

  if (!headings) {
    return null;
  }

  headings
    .filter((heading) => heading.depth !== 1 && heading.depth <= 3)
    .map((heading) => {
      return heading.depth === 2
        ? data.push(createDataObject(heading))
        : data[data.length - 1].values.push(createDataObject(heading));
    });
  return (
    <TOCDiv isDark={isDark}>
      <ul>
        {data.map((i) => (
          <ListItem item={i} key={i.name} />
        ))}
      </ul>
    </TOCDiv>
  );
};

export default TOC;

const TOCDiv = styled.div<{ isDark: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto 35px;

  width: 100%;
  max-width: 680px;
  ${mediaqueries.desktop`
    max-width: 507px;
  `}
  ${mediaqueries.tablet`
    max-width: 486px;
  `};
  ${mediaqueries.phablet`
    padding: 0 20px;
  `};

  a {
    transition: ${(p) => p.theme.colorModeTransition};
    color: ${(p) => p.theme.colors.accent};

    &:visited {
      color: ${(p) => p.theme.colors.accent};
      opacity: 0.85;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  ul {
    color: ${(p) => (p.isDark ? "#FFF" : p.theme.colors.primary)};
    margin: 0 0 0.5rem 0;
  }
  li {
    list-style-type: square;
    list-style-position: inside;
    margin: 10px 10px;
    margin-left: 0;
    font-size: 18px;
  }
  ul li ul {
    margin: 0 0 0 0;
  }
  ul li:before {
    content: "";
    position: absolute;
  }
  li ul li {
    list-style-type: circle;
    list-style-position: inside;
    font-size: 15px;
    margin-left: 10px;
  }
`;
